import React, { useEffect, useMemo, useRef, useState } from 'react';
import metamaskIconImg from '../img/metamask-icon.jpeg';
import { BUY_CURRENCY, CHAIN_NAME, chains, CURRENCY_FULL_NAME, CURRENCY_SYMBOL, DEFAULT_CHAIN, DEFAULT_RPC, EXPLORE_URL, PRESALE_ADDRESS, TOKEN_ADDRESS, TOKEN_DECIMALS, TOKEN_NAME, TOKEN_SYMBOL } from '../helper/constant';
import logoImg from '../img/logo.gif';
import tokenDisImg from '../img/TokenDistribution.png'
import { useCommonStats } from '../hooks/useCommon';
import Countdown, { zeroPad } from 'react-countdown';
import { toast } from 'react-toastify';
import { useAccount, useNetwork, useWalletClient } from "wagmi";
import tokenAbi from '../abis/token.json'
import presaleAbi from '../abis/presale.json'
import { ethers } from 'ethers';
import { formatPrice, getWeb3 } from '../helper/functions';
import { getTokenAmount } from '../helper/contractHelper';
import { useAccountStats } from '../hooks/useAccount';
import ConnectWallet from './ConnectWallet';
import { createPublicClient, getContract, http } from 'viem';
import { CopyToClipboard } from "react-copy-to-clipboard";




export default function PresaleSection() {
    const [updator, setUpdator] = useState(1);
    const commonStats = useCommonStats(updator)
    const accStats = useAccountStats(updator)
    const { address } = useAccount();
    const { chain } = useNetwork();
    const selectedId = 0;
    const [amount, setAmount] = useState('')
    const [tokenAmount, setTokenAmount] = useState(0);
    const timeoutRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const { data: signer } = useWalletClient();
    const [refcopy, setRefcopy] = useState(false);


    const publicClient = createPublicClient({
        chain: chains[0],
        transport: http(),
        batch: { multicall: true },
    });

    const presaleContract = useMemo(
        () =>
            getContract({
                address: PRESALE_ADDRESS,
                abi: presaleAbi,
                walletClient: signer ?? undefined,
                publicClient,
            }),
            // eslint-disable-next-line react-hooks/exhaustive-deps
        [signer]
    );

    const tokenContract = useMemo(
        () =>
            getContract({
                address: BUY_CURRENCY[selectedId].address,
                abi: tokenAbi,
                walletClient: signer ?? undefined,
                publicClient,
            }),
            // eslint-disable-next-line react-hooks/exhaustive-deps
        [signer]
    );
    const usdtAmount = 9;
    const [fixtokenamount, setFixtokenamount] = useState(0)

    useEffect(() => {
        const fetchTokenAmount = async () => {
            try {
                const result = await getTokenAmount(usdtAmount, BUY_CURRENCY[selectedId].decimals);
                setFixtokenamount(result);
            } catch (error) {
                console.error('Error fetching token amount:', error);
            }
        };

        fetchTokenAmount();
    }, [selectedId]); // Re-run when selectedId changes
    const networkDetails = {
        chainId: '0x' + DEFAULT_CHAIN.toString(16), // Mainnet chain ID in hexadecimal (Ethereum Mainnet)
        chainName: CHAIN_NAME,
        nativeCurrency: {
            name: CURRENCY_FULL_NAME,
            symbol: CURRENCY_SYMBOL,
            decimals: 18,
        },
        rpcUrls: [DEFAULT_RPC], // Replace with your RPC URL
        blockExplorerUrls: [EXPLORE_URL],
    };

    const handleAddTokenToMetamask = async () => {
        try {
            // Check if MetaMask is installed
            if (window.ethereum) {
                // Request to add the token
                const wasAdded = await window.ethereum.request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20',
                        options: {
                            address: TOKEN_ADDRESS,
                            symbol: TOKEN_SYMBOL,
                            decimals: TOKEN_DECIMALS
                        },
                    },
                });

                if (wasAdded) {
                    console.log('Token added to MetaMask!');
                } else {
                    console.log('Token not added.');
                }
            } else {
                console.error('MetaMask is not installed!');
            }
        } catch (error) {
            console.error('Error adding token to MetaMask:', error);
        }
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state if the countdown is finished
            return (
                <div className="presaleTimer mt-4">
                    <p className="timerItem" id="day">00</p>
                    <small className="timerDot">:</small>
                    <p className="timerItem" id="hr">00</p>
                    <small className="timerDot">:</small>
                    <p className="timerItem" id="min">00</p>
                    <small className="timerDot">:</small>
                    <p className="timerItem" id="sec">00</p>
                </div>
            );
        } else {
            // Render the countdown timer
            return (
                <div className="presaleTimer mt-4">
                    <p className="timerItem" id="day">{zeroPad(days, 2)}</p>
                    <small className="timerDot">:</small>
                    <p className="timerItem" id="hr">{zeroPad(hours, 2)}</p>
                    <small className="timerDot">:</small>
                    <p className="timerItem" id="min">{zeroPad(minutes, 2)}</p>
                    <small className="timerDot">:</small>
                    <p className="timerItem" id="sec">{zeroPad(seconds, 2)}</p>
                </div>
            );
        }
    };

    const handleChangeAmount = async (e) => {
        let value = e.target.value;
        const patt = /^\d+\.{0,1}\d{0,6}$/;
        if (value === '' || value === '0') {
            setAmount(value);
            setTokenAmount(0)
        }
        else if (patt.test(value)) {
            setAmount(value);
        }

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }


        timeoutRef.current = setTimeout(async () => {
            if (patt.test(value)) {
                let resAmount = BUY_CURRENCY && BUY_CURRENCY[selectedId] ? await getTokenAmount(value, BUY_CURRENCY[selectedId].decimals) : 0
                setTokenAmount(resAmount)
            }
        }, 1000);

    };


    const handleApprove = async () => {
        if (address) {
            if (parseInt(chain.id) === parseInt(DEFAULT_CHAIN)) {
                try {

                    setLoading(true);
                    let amount1 = ethers.utils.parseEther(amount.toString()).toString();
                    const hash = await tokenContract.write.approve([
                        PRESALE_ADDRESS,
                        amount1
                    ]);
                    toast.loading('Waiting for confirmation')

                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss()
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else if (response.status === false) {
                                toast.dismiss()
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else {
                                toast.dismiss()
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                        }
                    }, 5000);
                }
                catch (error) {
                    console.log(error.message)
                    toast.dismiss()
                    toast.error(error.shortMessage ? error.shortMessage : error.message);
                    setLoading(false);

                }
            }
            else {
                toast.dismiss()
                toast.error('Please select Binance Mainnet Network !');
                setLoading(false);

            }

        }
        else {
            toast.dismiss()
            toast.error('Please Connect Wallet!');
            setLoading(false);

        }
    }

    const handleSubmit = async () => {
        if (address) {
            if (parseInt(chain.id) === parseInt(DEFAULT_CHAIN)) {
                try {

                    setLoading(true);
                    let amount1 = ethers.utils.parseEther(amount.toString()).toString();
                    const hash = await presaleContract.write.buyToken([
                        amount1
                    ]);
                    toast.loading('Waiting for confirmation')

                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss()
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else if (response.status === false) {
                                toast.dismiss()
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else {
                                toast.dismiss()
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                        }
                    }, 5000);
                }
                catch (error) {
                    console.log(error.message)
                    toast.dismiss()
                    toast.error(error.shortMessage ? error.shortMessage : error.message);
                    setLoading(false);

                }
            }
            else {
                toast.dismiss()
                toast.error('Please select Binance Mainnet Network !');
                setLoading(false);

            }

        }
        else {
            toast.dismiss()
            toast.error('Please Connect Wallet!');
            setLoading(false);

        }
    }

    const handlePercentageClick = (percentage) => {
        const maxAmount = accStats.tokenBal;
        const newAmount = (maxAmount * percentage) / 100;
        setAmount(newAmount.toString());
        // Assuming getTokenAmount needs a string value
        getTokenAmount(newAmount, BUY_CURRENCY[selectedId].decimals).then(setTokenAmount);
    };



    return (
        <section class="cover presale-section" data-aos="fade-up" id="token-sale">
            {/* <img src={coverBgImg} alt="coverGIF" class="coverGif" /> */}
            <div class="row d-flex justify-content-around align-items-center">
                <div className='col-12 col-md-4'>
                    <h6 className='text-center mb-4'>Buy KETA tokens for equity in our gold processing plant</h6>
                    <div class="coverAction">
                        <div class="presaleContainer">
                            <div class="presaleTop">
                                <img class="presaleIcon" src={logoImg} alt="Presale Icon" />
                                <div class="presaleHead">
                                    <h1 class="presaleTokenName">{TOKEN_NAME}</h1>
                                    <p class="presalePair">{TOKEN_SYMBOL} / <span id="exchange-currency">BNB</span></p>
                                </div>
                                <div class="presaleAuth">

                                    <a href={`${EXPLORE_URL}/token/${TOKEN_ADDRESS}`}  rel="noreferrer" target="_blank" class="verified mx-1">
                                        <i class="fa-solid fa-arrow-up-right-from-square"></i>Explorer
                                    </a>
                                    <a href="https://ketagold.com/info/Auditing"  rel="noreferrer" target="_blank" class="audited">
                                        <i class="fa-solid fa-shield"></i> Audited
                                    </a>

                                </div>
                            </div>
                            <button onClick={() => handleAddTokenToMetamask()} type='button' class="metamask-btn audited">
                                <img
                                    src={metamaskIconImg}
                                    alt="Metamask Icon"
                                    height="15px"
                                />
                                Add to Metamask
                            </button>
                            <Countdown
                                date={commonStats.endtime ? Number(commonStats.endtime) * 1000 : 0}  // End time for the countdown
                                renderer={renderer}  // Custom renderer function
                                key={Math.random()}
                            />
                            <div class="presaleAction">
                                <div class="presaleRoller" id="loader"><div class="loader"></div></div>
                                <div class="balanceStrip">Your Wallet Balance is <span id="balance">{accStats.tokenBal ? formatPrice(accStats.tokenBal, 10) : 0}</span> <small id="balance-currency">{BUY_CURRENCY[0]?.symbol}</small></div>
                                <div class="currencyDrop">
                                    {BUY_CURRENCY.map((items, key) => (
                                        <div class="currencyDropItem active" key={key}>
                                            <img
                                                src={items.image}
                                                alt="currency"
                                            />
                                            <h3>{items.symbol}</h3>
                                        </div>
                                    ))}
                                </div>
                                <input value={amount} onChange={(e) => handleChangeAmount(e)} type="text" class="presaleInput" placeholder="0" aria-label="Username" aria-describedby="basic-addon1" />
                                <div className='text-purple my-1'>
                                    You will get {TOKEN_SYMBOL} : {formatPrice(tokenAmount, 10)}
                                </div>
                                <div className="balanceScale">
                                    <p className="balanceScaleItem" id="scale-10" onClick={() => handlePercentageClick(10)}>10%</p>
                                    <p className="balanceScaleItem" id="scale-25" onClick={() => handlePercentageClick(25)}>25%</p>
                                    <p className="balanceScaleItem" id="scale-50" onClick={() => handlePercentageClick(50)}>50%</p>
                                    <p className="balanceScaleItem" id="scale-75" onClick={() => handlePercentageClick(75)}>75%</p>
                                    <p className="balanceScaleItem" id="scale-100" onClick={() => handlePercentageClick(100)}>100%</p>
                                </div>
                                <div class="presaleActionBtnWrapper">
                                    {address ? (
                                        parseFloat(accStats.isApproved) >= amount ? (
                                            <button onClick={() => handleSubmit()} type="button" disabled={!commonStats.saleStatus || loading} className="presaleActionBtn">
                                                {loading ? 'Loading...' : 'Buy Now'}
                                            </button>
                                        ) : (
                                            <button onClick={() => handleApprove()} type="button" disabled={!commonStats.saleStatus || loading} className="presaleActionBtn">
                                                {loading ? 'Loading...' : 'Approve'}
                                            </button>
                                        )

                                    ) : (
                                        <ConnectWallet className="presaleActionBtn" />
                                    )}

                                </div>
                            </div>
                            <h4 class="presaleConversion">{usdtAmount}
                                <span id="conversion-symbol"> USDT</span>
                                <i class="fa-solid fa-arrow-right-long m-2"></i>
                                <span id="conversion-amount">{formatPrice(fixtokenamount)}</span>
                                <span>{" "}{TOKEN_SYMBOL}</span>
                            </h4>
                            <div class="presaleStats">
                                <div class="statTop">
                                    <p id="presale-status">ACTIVE</p>
                                    <p id="complete-percent">{commonStats.totalPer} %</p>
                                </div>
                                <div style={{ height: '4px', width: '100%', background: 'rgb(30, 30, 30)', borderRadius: '50px', overflow: 'hidden' }}>
                                    <div style={{ width: `${commonStats.totalPer}%`, background: 'rgb(0, 255, 180)', height: '4px' }}></div>
                                </div>
                                <div class="statBottom">
                                    <p id="raised-amount">${formatPrice(commonStats.totalraisedAmount)}</p>
                                    <p>Hard Cap: <span id="hard-cap">$1,020,000</span></p>
                                </div>
                            </div>
                            <a target="_blank" rel="noreferrer" href={`${EXPLORE_URL}/address/${PRESALE_ADDRESS}`} class="contractExplorer mx-2">
                                View Presale Smart Contract Address
                                <i class="fa-solid fa-arrow-up-right-from-square"></i>
                            </a>
                            <CopyToClipboard text={window.location.href} onCopy={() => {
                                setRefcopy(true);
                                setTimeout(() => {
                                    setRefcopy(false);
                                }, 2000)
                            }}>
                                <div class="metamask-btn">
                                    <i class="fa-solid fa-share-from-square"></i>
                                    {refcopy ?  'Copied' :  'Share Presale Link'}
                                </div>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-7">
                    <div className='row'>
                        <div className='col-6'>
                            <div className="aboutTextSection">
                                <h4 className='mb-2'>Private Sale:</h4>
                                <p className="aboutText mb-2"><i className="fa-solid fa-location-arrow"></i> Allocation: 200,000,000 KETA</p>
                                <p className="aboutText mb-2"><i className="fa-solid fa-location-arrow"></i> Price: 0.0009 USDT per KETA (90 USDT for 100,000 tokens)</p>
                                <p className="aboutText mb-2"><i className="fa-solid fa-location-arrow"></i> Minimum purchase: 10,000 KETA tokens</p>
                                <p className="aboutText mb-2"><i className="fa-solid fa-location-arrow"></i> Bonus: 15% off token purchase</p>
                                <p className="aboutText"><i className="fa-solid fa-location-arrow"></i> Target Raise: $180,000</p>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="aboutTextSection">
                                <h4 className='mb-2'>Public Sale:</h4>
                                <p className="aboutText mb-2"><i className="fa-solid fa-location-arrow"></i> Allocation: 800,000,000 KETA</p>
                                <p className="aboutText mb-2"><i className="fa-solid fa-location-arrow"></i> Price: $0.00105 per KETA ($105 for 100,000 tokens)</p>
                                <p className="aboutText mb-2"><i className="fa-solid fa-location-arrow"></i> Minimum purchase: 10,000 KETA tokens</p>
                                <p className="aboutText"><i className="fa-solid fa-location-arrow"></i> Target Raise: $840,000</p>
                            </div>
                        </div>
                        <div class="col-6 col-md-6">
                            <div class="aboutTextSection">
                                <h4 className='mb-2'>Targets:</h4>
                                <p class="aboutText mb-2"><i class="fa-solid fa-location-arrow"></i>Soft Cap: $500,000</p>
                                <p class="aboutText"><i class="fa-solid fa-location-arrow"></i> Hard Cap: $1,020,000</p>
                            </div>
                        </div>
                        <div class="col-6 col-md-6">
                            <div class="aboutTextSection">
                                <h4 className='mb-2'>Token Distribution:</h4>
                                <p class="aboutText mb-2"><i class="fa-solid fa-location-arrow"></i> Private Sale: 10%</p>
                                <p class="aboutText mb-2"><i class="fa-solid fa-location-arrow"></i> Public Sale: 40%</p>
                                <p class="aboutText mb-2"><i class="fa-solid fa-location-arrow"></i> Plant Operation: 40%</p>
                                <p class="aboutText mb-2"><i class="fa-solid fa-location-arrow"></i> Team Allocation: 10%</p>
                            </div>
                        </div>
                        <div className='col-12 mt-4 d-flex justify-content-center'>
                            <img src={tokenDisImg} alt='token' className='bg-white distribution-img' />
                        </div>
                    </div>
                </div>
            </div>
        </section >

    )
}
