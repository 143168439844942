import { bsc, bscTestnet } from "wagmi/chains";
import usdtImg from '../img/usdt.png';

export const CHAIN_NAME = process.env.REACT_APP_CHAIN_NAME;
export const CURRENCY_FULL_NAME = process.env.REACT_APP_CURRENCY_FULL_NAME;
export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
export const DEFAULT_RPC = process.env.REACT_APP_DEFAULT_RPC;
export const DEFAULT_CHAIN = process.env.REACT_APP_DEFAULT_CHAIN;
export const MULTICALL = process.env.REACT_APP_MULTICALL;
export const TOKEN_ADDRESS = process.env.REACT_APP_TOKEN_ADDRESS;
export const EXPLORE_URL = process.env.REACT_APP_EXPLORE_URL;
export const TOKEN_SYMBOL = process.env.REACT_APP_TOKEN_SYMBOL;
export const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME;
export const TOKEN_DECIMALS = process.env.REACT_APP_TOKEN_DECIMALS;
export const CURRENCY_SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
export const PRESALE_ADDRESS = process.env.REACT_APP_PRESALE_ADDRESS;
export const TOTAL_SUPPLY = process.env.REACT_APP_TOTAL_SUPPLY;
export const SOFT_CAP = process.env.REACT_APP_SOFT_CAP;
export const HARD_CAP = process.env.REACT_APP_HARD_CAP;
export const STAKE_ADDRESS = process.env.REACT_APP_STAKE_ADDRESS;
export const STAKE_TIME = process.env.REACT_APP_STAKE_TIME;
export const chains = [bsc]



export const BUY_CURRENCY = [
    {
        address : "0x55d398326f99059fF775485246999027B3197955",
        name : "Tether USD",
        symbol : "USDT",
        image : usdtImg,
        decimals : 18
    }
]