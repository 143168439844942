import React from 'react'

export default function RoadmapSection() {
    return (
        <section class="roadmapSection aos-init aos-animate" id="roadmap" data-aos="fade-up">
            <h3 className='text-center mb-2 hero-title' data-aos="fade-up">Our Path to Success: KETAGOLD Roadmap</h3>
            <div className='row d-flex justify-content-center mb-5' data-aos="fade-up">
                <div className='col-12 col-md-7'>
                    <p className='text-center'>
                        Discover the milestones and objectives we have set for KETAGOLD as we work towards establishing a gold processing plant in Tanzania. Our roadmap outlines the key steps we will take to maximize returns for our investors.
                    </p>
                </div>
            </div>
            <div class="timeline">
                <div class="container-roadmap left">

                    <i class="icon ri-heart-line"></i>
                    <div class="content">
                        <div class="date">August - September 2024</div>
                        <ul>
                            <li>Token Generation Event</li>
                            <li>Website launch</li>
                            <li>Private sale begins</li>
                            <li>Marketing campaign launch</li>
                        </ul>
                    </div>
                </div>
                <div class="container-roadmap right">

                    <i class="icon ri-heart-line"></i>
                    <div class="content">
                        <div class="date">After reaching Soft Cap</div>
                        <ul>
                            <li>Private sale ends</li>
                            <li>Lucky draw notice</li>
                            <li>Staking platform launch</li>
                            <li>Plant construction begins</li>
                            <li>Public sale begins</li>
                        </ul>
                    </div>
                </div>
                <div class="container-roadmap left">

                    <i class="icon ri-heart-line"></i>
                    <div class="content">
                        <div class="date">After reaching Hard Cap</div>
                        <ul>
                            <li>Public sale ends</li>
                            <li>Lucky draw winners announced</li>
                        </ul>
                    </div>
                </div>
                <div class="container-roadmap right">

                    <i class="icon ri-heart-line"></i>
                    <div class="content">
                        <div class="date">Three months after the start of plant construction</div>
                        <ul>
                            <li>Plant construction completed</li>
                            <li>First dividend distribution</li>
                        </ul>
                    </div>
                </div>
                <div class="container-roadmap left">

                    <i class="icon ri-heart-line"></i>
                    <div class="content">
                        <div class="date">After the third dividend distribution</div>
                        <ul>
                            <li>Expansion of gold processing operations</li>
                            <li>Community growth initiatives</li>
                        </ul>
                    </div>
                </div>
                <div class="container-roadmap right">

                    <i class="icon ri-heart-line"></i>

                    <div class="content">
                        <div class="date">September - October 2025</div>
                        <ul>
                            <li>Building new gold processing facilities</li>
                            <li>New token launch</li>
                        </ul>
                    </div>
                </div>
            </div>

        </section>

    )
}
