import {
    EthereumClient,
    w3mConnectors,
    w3mProvider,
  } from "@web3modal/ethereum";
import { configureChains, createConfig } from "wagmi";
import { chains, PROJECT_ID } from "../helper/constant";
  
  
  // Wagmi client
  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId : PROJECT_ID }),
  ]);
  
  export const wagmiClient = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId : PROJECT_ID, chains }),
    publicClient,
  });
  
  // Web3Modal Ethereum Client
  export const ethereumClient = new EthereumClient(wagmiClient, chains);